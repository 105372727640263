import {Autocomplete, Badge, Button, createFilterOptions, Divider, Stack, TextField} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import {Calendar, CalendarParticipant} from "./models";
import {DataStore} from "@aws-amplify/datastore";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {Amplify, Auth} from "aws-amplify";

import awsConfig from './aws-exports';
import {useSnackbar} from "notistack";

Amplify.configure(awsConfig);

interface CalendarOption {
    participant?: CalendarParticipant,
    newCalendarName?: string,
    label: string
}

function mapToOptions(input: (CalendarParticipant | undefined)[]): CalendarOption[] {
    const output: CalendarOption[] = [];
    for (const entry of input) {
        if (entry?.calendar?.name) {
            output.push({
                participant: entry,
                label: entry.calendar.name
            });
        }
    }
    return output;
}

const calFilter = createFilterOptions<CalendarOption>();

export default function CalendarManager(props: {
    value?: CalendarParticipant | undefined,
    onChange?: (newValue: CalendarParticipant | undefined) => void
}) {

    const navigate = useNavigate();
    const pathParams = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const [options, setOptions] = React.useState<CalendarOption[]>([]);
    const [participantCount, setParticipantCount] = React.useState(0);

    const changeCalendarSelection = (newValue: CalendarParticipant) => {
        console.log("change selection", newValue);
        navigate('/' + newValue.calendar.id);
        props.onChange?.(newValue);
    };

    useEffect(() => { // participation data
        console.log("subscribing to participation");
        const sub = DataStore.observeQuery(CalendarParticipant).subscribe(async msg => {
            console.log("sub part", msg);
            const creds = await Auth.currentUserCredentials();
            const options = mapToOptions(msg.items.filter(item => item.owner === creds.identityId));
            setOptions(options);

            // url-based join/select calendar
            const {calendarId} = pathParams;
            if (calendarId && calendarId !== props?.value?.calendar?.id) {
                const matches = options.filter(opt => opt.participant?.calendar.id === calendarId);
                if (matches.length === 1) {
                    const [match] = matches;
                    if (match.participant && match.participant.id !== props?.value?.id) {
                        props.onChange?.(match.participant);
                    }
                } else if (msg.isSynced && matches.length === 0) {
                    const calendar = await DataStore.query(Calendar, calendarId);
                    console.log("trying to join calendar", calendar);
                    if (calendar) {
                        const creds = await Auth.currentUserCredentials();
                        const participant = await DataStore.save(new CalendarParticipant({
                            calendar,
                            owner: creds.identityId
                        }));
                        if (participant) {
                            props.onChange?.(participant);
                        } else {
                            navigate('/');
                            enqueueSnackbar('Unable to join calendar', {variant: "error"});
                        }
                    } else {
                        navigate('/');
                        enqueueSnackbar('Unable to find calendar', {variant: "error"});
                    }
                } else {
                    console.log("have calendar url but waiting for sync");
                }
            }
        });
        return () => sub.unsubscribe();
    }, [pathParams]);

    useEffect(() => {
        // update participants badge
        if (props?.value) {
            DataStore.query(CalendarParticipant)
                .then(parts => setParticipantCount(parts.filter(part => part.calendar.id === props.value?.calendar.id).length));
        }
    }, [props.value, setParticipantCount])

    const doCreateCalendar = async (name: string) => {
        const calendar = await DataStore.save(new Calendar({name}));
        console.log("save cal", calendar);
        if (calendar) {
            const creds = await Auth.currentUserCredentials();
            const participant = await DataStore.save(new CalendarParticipant({
                calendar,
                owner: creds.identityId
            }));
            console.log("save part", participant);
            if (participant) {
                changeCalendarSelection(participant);
            } else {
                enqueueSnackbar('Unable to participate in new calendar', {variant: "error"});
            }
        } else {
            enqueueSnackbar('Unable to create new calendar', {variant: "error"});
        }
    };

    const unparticipate = async () => {
        if (props.value) {
            await DataStore.delete(props.value);
            navigate('/');
            enqueueSnackbar('Occasion abandoned!', {variant: "warning"});
        }
    }

    const participationDetail = () => {
        if (participantCount < 2) {
            enqueueSnackbar(`You are the only participant, try sharing this page ;)`, {variant: 'info'});
        } else {
            enqueueSnackbar(`There are ${participantCount} participants for this occasion!`, {variant: 'info'});
        }
    }

    return (
        <Stack spacing={2} alignItems='stretch'>
            <Autocomplete freeSolo selectOnFocus clearOnBlur handleHomeEndKeys
                          options={options}
                          value={mapToOptions([props.value])[0] || null}
                          renderInput={(params) => (<TextField {...params} label="What's the occasion?"/>)}
                          sx={{marginTop: "1rem"}}
                          filterOptions={(options, params) => {
                              const filtered = calFilter(options, params);
                              const {inputValue} = params;
                              if (filtered.length < 1 && inputValue && inputValue.length > 0) {
                                  filtered.push({
                                      newCalendarName: inputValue,
                                      label: `Create new calendar: "${inputValue}"?`
                                  });
                              }
                              return filtered;
                          }}
                          onChange={(_event, newValue) => {
                              const selected: CalendarOption = newValue as CalendarOption;
                              if (selected) {
                                  if (selected.participant) {
                                      changeCalendarSelection(selected.participant);
                                  } else if (selected.newCalendarName) {
                                      // Create a new value from the user input
                                      doCreateCalendar(selected.newCalendarName);
                                  } else {
                                      // bad data
                                  }
                              }
                          }}
                          isOptionEqualToValue={(left, right) => {
                              const lOpt = left as CalendarOption;
                              const rOpt = right as CalendarOption;
                              if (lOpt && rOpt) {
                                  if (lOpt.participant?.id === rOpt.participant?.id) {
                                      return true;
                                  }
                              }
                              return false;
                          }}
            />
            <Stack direction='row'
                   spacing={2}
                   divider={<Divider orientation="vertical" flexItem/>}
                   alignItems='center'
                   justifyContent='center'
            >
                <Badge badgeContent={participantCount}
                       color='info'>
                    <Button variant='outlined'
                            disabled={!props.value}
                            onClick={participationDetail}>
                        <PersonIcon/>
                    </Button>
                </Badge>
                <Button color='error'
                        variant='outlined'
                        disabled={!props.value}
                        onClick={unparticipate}>
                    <DeleteIcon/>
                </Button>
            </Stack>
        </Stack>
    );
}