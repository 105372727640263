import {Stack} from "@mui/material";
import {Amplify, Auth, DataStore} from "aws-amplify";
import React, {useEffect} from "react";
import {Calendar as ReactCalendar, DateObject} from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import {CalendarEntry, CalendarParticipant} from "./models";
import awsConfig from './aws-exports';
import {useSnackbar} from "notistack";

Amplify.configure(awsConfig);

const weekDays = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
export const DATE_FMT = "YYYY-MM-DD";

interface DateEntry {
    entry?: CalendarEntry,
    date: DateObject
}

function mapToDateEntry(entries: CalendarEntry[]): DateEntry[] {
    const dateEntries: DateEntry[] = [];
    for (const entry of entries) {
        if (entry.date) {
            dateEntries.push({
                entry: entry,
                date: new DateObject().setFormat(DATE_FMT).parse(entry.date)
            })
        }
    }
    return dateEntries;
}

function cardinal<T>(value: T | T[] | null | undefined): T[] {
    let retVal: T[] = [];
    if (value) {
        if (Array.isArray(value)) {
            retVal = value;
        } else {
            retVal.push(value);
        }
    }
    return retVal;
}

export default function CalendarEditor(props: {
    participant: CalendarParticipant | undefined
}) {

    const {enqueueSnackbar} = useSnackbar();

    const [dates, setDates] = React.useState<DateEntry[]>([]);

    useEffect(() => { // entry data
        console.log("editor participant", props.participant);
        if (props.participant) {
            const sub = DataStore.observeQuery(CalendarEntry).subscribe(async msg => {
                console.log("sub entries", msg);
                if (props.participant) {
                    const matches = msg.items.filter(entry => entry.participant.id === props.participant?.id);
                    setDates(mapToDateEntry(matches));
                } // else we aren't ready
            });
            return () => sub.unsubscribe();
        }
    }, [props.participant]);

    const doDateChange = async (data: DateObject | DateObject[] | null) => {

        let updates = cardinal(data);
        if (updates.length < 1 || !props.participant?.id) {
            return;
        }

        let removes: DateEntry[] = [];
        let adds: DateEntry[];
        if (dates) {
            removes = dates.filter(prevDate => !updates.some(update => update === prevDate.date));
            adds = updates.filter(update => !dates.some(prevDate => prevDate.date === update)).map(update => ({date: update}));
        } else {
            adds = updates.map(update => ({date: update}));
        }

        for (const remove of removes) {
            if (remove.entry) {
                const result = await DataStore.delete(remove.entry);
                console.log("delete date", result);
                if (!result) {
                    enqueueSnackbar('Unable to remove date', {variant: "warning"});
                }
            }
        }

        const creds = await Auth.currentUserCredentials();
        for (const add of adds) {
            const result = await DataStore.save(new CalendarEntry({
                date: add.date.format(DATE_FMT),
                participant: props.participant,
                owner: creds.identityId
            }));
            console.log("add date", result);
            if (!result) {
                enqueueSnackbar('Unable to add date', {variant: "warning"});
            }
        }
    };

    return (
        <Stack alignItems="center">
            <ReactCalendar multiple
                           format={DATE_FMT}
                           weekDays={weekDays}
                           plugins={[<DatePanel/>]}
                           zIndex={0}
                           disabled={!props.participant}
                           value={dates.map(date => date.date)}
                           onChange={doDateChange}
            />
        </Stack>
    );
}