import {Stack} from "@mui/material";
import CalendarManager from "./CalendarManager";
import CalendarEditor from "./CalendarEditor";
import React from "react";
import {CalendarParticipant} from "./models";
import Matches from "./Matches";

export default function CalendarBody() {

    const [participant, setParticipant] = React.useState<CalendarParticipant>();

    return (
        <Stack spacing={3} alignItems="stretch" sx={{margin: '1rem'}}>
            <CalendarManager value={participant} onChange={setParticipant}/>
            <Stack spacing={3} alignContent="center">
                <CalendarEditor participant={participant}/>
                <Matches participant={participant}/>
            </Stack>
        </Stack>
    );
}