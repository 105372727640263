import {CalendarMatch, CalendarParticipant} from "./models";
import React, {useEffect} from "react";
import {Amplify, DataStore} from "aws-amplify";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import awsConfig from "./aws-exports";
import {AuthModeStrategyType} from "@aws-amplify/datastore";
import {CalendarToday} from "@mui/icons-material";
import ShareIcon from '@mui/icons-material/Share';
import {useSnackbar} from "notistack";
import moment from "moment";
import {DATE_FMT} from "./CalendarEditor";

Amplify.configure({
    ...awsConfig,
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    }
})

export default function Matches(props: {
    participant: CalendarParticipant | undefined
}) {
    const {enqueueSnackbar} = useSnackbar();

    const [dates, setDates] = React.useState<string[]>([]);

    useEffect(() => {
        console.log("matches participant", props.participant);
        if (props.participant) {
            const sub = DataStore.observeQuery(CalendarMatch).subscribe(async msg => {
                console.log("sub matches", msg);
                if (props.participant) {
                    const matches = msg.items.filter(match => match.calendar.id === props.participant?.calendar.id);
                    const dates = matches.map(match => match.date);
                    const dateSet = new Set(dates);
                    setDates([...Array.from(dateSet)]);
                }
            });
            return () => sub.unsubscribe();
        }
    }, [props.participant]);

    const shareDate = async (date: string) => {
        if (navigator.share) {
            try {
                const dateMoment = moment(date, DATE_FMT);
                await navigator.share({
                    title: 'Experiment results!',
                    text: `Hey! Are you free ${dateMoment.startOf('day').fromNow(true)} from now (${date})?`,
                    url: window.location.toString()
                });
                enqueueSnackbar('Shared!', {variant: "success"});
            } catch (err) {
                enqueueSnackbar('Error sharing', {variant: "error"});
            }
        } else {
            enqueueSnackbar('Unable to share', {variant: "warning"});
        }
    };

    if (dates.length > 0) {
        return (
            <Card sx={{minWidth: 275}}>
                <CardHeader title="Let's hang!" subheader="Your matching dates:"/>
                <CardContent>
                    <List disablePadding>
                        {dates.map(date => (
                            <ListItem secondaryAction={
                                <IconButton edge="end" aria-label="share" onClick={() => shareDate(date)}>
                                    <ShareIcon/>
                                </IconButton>
                            }>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CalendarToday/>
                                    </ListItemIcon>
                                    <ListItemText primary={date}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Card>
        );
    } else {
        return null;
    }
}