import React from 'react';
import {AppBar as MuiAppBar, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import {DataStore} from 'aws-amplify';
import {SettingsApplications} from "@mui/icons-material";

export default function AppBar() {

    const [menuOpen, setMenuOpen] = React.useState(false);

    return (
        <MuiAppBar position='static'>
            <Toolbar>
                <SafetyDividerIcon fontSize='large' sx={{marginRight: '1rem'}}/>
                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    Double-blind Date
                </Typography>
                <IconButton
                    id='systemIcon'
                    size="large"
                    aria-label="system menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={(e) => setMenuOpen(true)}
                    color="inherit"
                >
                    <SettingsApplications/>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={() => document.getElementById('systemIcon') as Element}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={menuOpen}
                    onClose={() => setMenuOpen(false)}
                >
                    <MenuItem
                        onClick={() => DataStore.clear().then(() => DataStore.start().then(() => setMenuOpen(false)))}>Clear
                        cache</MenuItem>
                </Menu>
            </Toolbar>
        </MuiAppBar>
    );
}