import {Container, Stack, Typography} from '@mui/material';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import {Route, Routes} from 'react-router-dom';
import React from 'react';
import CalendarBody from "./CalendarBody";
import EmptyBody from "./EmptyBody";
import AppBar from "./AppBar";

function App() {
    return (
        <Container fixed className='App' sx={{'padding': 0}}>
            <Stack>
                <AppBar/>
                <Routes>
                    <Route path="/:calendarId" element={<CalendarBody/>}/>
                    <Route path="*" element={<EmptyBody/>}/>
                </Routes>
            </Stack>
        </Container>
    );
}

export default App;
