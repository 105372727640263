import {Card, CardContent, Stack, Typography} from "@mui/material";
import CalendarManager from "./CalendarManager";
import React from "react";

export default function EmptyBody() {
    return (
        <Stack spacing={3} alignItems="stretch" sx={{margin: '1rem'}}>
            <CalendarManager/>
            <Stack spacing={3} alignContent="center">
                <Card variant="outlined">
                    <CardContent>
                        <Typography sx={{fontSize: 14}} gutterBottom>
                            Welcome to Double-blind Date
                        </Typography>
                        <Typography variant="body2">
                            Create a event, choose some dates, share the url!
                        </Typography>
                    </CardContent>
                </Card>
            </Stack>
        </Stack>
    );
}